import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { Resource } from 'components/Landing/Resource/Resource'
import { Box } from 'components/primitives/Box'
import { Text } from 'components/primitives/Text'
import { Article } from 'components/Landing/Press/Article'

import CollabScout from 'assets/logos/collabscout.png'

const Policy: React.FC<RouteComponentProps> = () => {
  return (
    <Resource title='Press & Media'>
      <Box maxWidth='60rem' mx='auto' pb='medium'>
        <Text as='p' fontSize='big' mb='medium' textAlign='center'>
          For press inquiries, please send us an e-mail at{' '}
          <a href='mailto:press@doseapp.io' className='link'>
            press@doseapp.io
          </a>
          . If you&apos;re looking for our press kit, you can download it{' '}
          <a
            href='https://drive.google.com/drive/folders/1wS_b6Wp07Ptp055oZ2EIPV1Hplb7Qiv3?usp=sharing'
            className='link'
          >
            here
          </a>
          .
        </Text>
      </Box>

      <Article
        cover={CollabScout}
        text='Interview with the developer of Dose'
        href='https://collabscout.io'
      />
    </Resource>
  )
}

export default Policy
