import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Page } from '../shared/Page'
import { Text, H1 } from 'components/primitives/Text'
import SEO from 'components/shared/SEO'
import { Grid } from 'react-styled-flexboxgrid'
import { media } from 'components/primitives/Responsive'
import { space, colors } from 'services/theme'

interface Props {
  title: string
}

export const Resource: React.FC<Props> = ({ title, children }) => {
  return (
    <Page>
      <SEO title={title} />
      <ResourceStyles />

      <Grid style={{ marginBottom: space.medium }}>
        <Heading>{title}</Heading>
        {children}
      </Grid>
    </Page>
  )
}

export const Paragraph: React.FC = ({ children }) => (
  <Text as='p' fontSize='big' mb='medium'>
    {children}
  </Text>
)

export const List: React.FC = ({ children }) => (
  <ul style={{ marginLeft: '5rem' }}>{children}</ul>
)

const Heading = styled(H1)`
  ${media.minMd} {
    font-size: 4.8rem;
    padding: ${space.medium};
    text-align: center;
  }
`

export const ResourceStyles = createGlobalStyle`
  a.link {
    color: ${colors.text};
    font-weight: 600;
    text-decoration: underline;
  }
`
