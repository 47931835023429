import React from 'react'
import { Box } from 'components/primitives/Box'
import { Text } from 'components/primitives/Text'
import { colors } from 'services/theme'
import { FullCenterView } from 'components/primitives/Views'
import styled from 'styled-components'
import { LANDING, GA } from 'services/analytics'

interface Props {
  cover: string
  text: string
  href: string
}

export const Article: React.FC<Props> = ({ cover, text, href }) => {
  const openArticle = () => {
    GA.event({ category: LANDING, action: 'OpenArticle', label: href })
    window.open(href)
  }

  return (
    <Container onClick={openArticle}>
      <Box maxWidth={450} height={170} bg='rgba(155,155,155,0.05)'>
        <FullCenterView>
          <img
            src={cover}
            alt='text'
            style={{ width: '66%', maxWidth: '30rem' }}
          />
        </FullCenterView>
      </Box>

      <Text as='p' fontSize='big' textAlign='center' m='1.5rem'>
        {text}
      </Text>
    </Container>
  )
}

const Container = styled(Box)`
  border: 1px solid ${colors.accent3};
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

Container.defaultProps = {
  maxWidth: '45.2rem',
  mx: 'auto',
  my: 'medium',
  overflow: 'hidden'
}
